export const requiredPermissions = {
    "importList": [
        "action",
        "subtaskStep",
        "rating",
        "taggedLocation",
        "trustedAttribute",
        "component",
        "subtask",
        "subtaskStepTemplate",
        "subtaskTemplate",
        "productI18N",
        "product",
        "manufacturer",
        "note",
        "documentLanguage",
        "masterDataDocument",
        "customDocument",
        "documentI18N",
        "trustedAttributeValueI18N",
        "trustedAttributeKeyI18N",
        "keyValue"
    ],
    "exportList": [
        "keyValue",
        "trustedAttributeKeyI18N",
        "trustedAttributeValueI18N",
        "documentI18N",
        "customDocument",
        "masterDataDocument",
        "documentLanguage",
        "note",
        "manufacturer",
        "product",
        "productI18N",
        "user",
        "ratingSchema",
        "ratingLevel",
        "subtaskTemplate",
        "subtaskStepTemplate",
        "subtask",
        "component",
        "trustedAttribute",
        "location",
        "taggedLocation",
        "task",
        "rating",
        "subtaskStep",
        "action"
    ]
}